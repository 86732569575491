import { useEffect } from 'react';
import { Admin, Resource, CustomRoutes, Authenticated, defaultTheme, useGetIdentity } from 'react-admin';
import { Route } from 'react-router-dom';
import { authProvider, i18nProvider } from './providers';
import { development } from './global';

import EissLayout from './layout/EissLayout';
import LoginForm from './layout/LoginForm';
import Dashboard from './layout/Dashboard';
import Locked from './layout/Locked';
import MFA from './layout/MFA';
import ResetPassword from './layout/ResetPassword';
import ForgotPassword from './layout/ForgotPassword';

export const theme = {
	...defaultTheme,
	sidebar: {
		...defaultTheme.sidebar,
		width: 0,
		closedWidth: 0
	},
	palette: {
		...defaultTheme.palette,
		primary: {
			main: '#448ab6'
		},
		secondary: {
			main: '#448ab6'
		}
	}
};

export const redirectLogin = () => {
	sessionStorage.setItem("application", "/ui/home/");
	window.location.href = development ? '#/login' : "/ui/login/";
};

const Profile = () => {
	const { data: identity } = useGetIdentity();

	useEffect(() => {
		if (identity && identity.id) {
			window.location.href =`/ui/users/#/users/${identity.id}/show`;
		}
	}, [identity]);

	return <></>;
}

const App = () => (
	<Admin disableTelemetry
		theme={theme}
		i18nProvider={i18nProvider}
		authProvider={authProvider}
		dataProvider={() => Promise.resolve()} // no dataProvider for this app
		layout={EissLayout}
		loginPage={() => <LoginForm />}
	>
		<Resource name="dashboard" />
		<CustomRoutes noLayout>
			<Route path="/forgotpassword" element={<ForgotPassword />} />
			<Route path="/locked" element={<Locked />} />
			<Route path="/reset/:id" element={<ResetPassword />} />
			<Route path="/expire/:id" element={<ResetPassword />} />
			<Route path="/new/:id" element={<ResetPassword />} />
			<Route path="/mfa" element={<MFA />} />
		</CustomRoutes>
		<CustomRoutes>
			<Route path="/dashboard" element={<Authenticated><Dashboard /></Authenticated>} />
			<Route path="/profile" element={<Authenticated><Profile /></Authenticated>} />
		</CustomRoutes>
	</Admin>
);

export default App;
