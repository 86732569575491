import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	Box,
	Card,
	CardActions,
	CircularProgress,
	Typography
} from '@mui/material';
import {
	Form,
	Button,
	Notification,
	TextInput,
	useNotify,
	useRedirect,
	required
} from 'react-admin';
import { lightBlue, red } from '@mui/material/colors';
import Logo from './Logo';
import { resetService } from './resetService';

interface FormValues {
	email?: string;
}

const ForgotPassword = () => {
	const [loading, setLoading] = useState(false);
	const [sent, setSent] = useState("");
	const notify = useNotify();
	const redirect = useRedirect();
	const form = useForm();

	const onSubmit = (values: FormValues) => {
		setLoading(true);
		return resetService.sendPasswordResetEmail(values.email)
		.then(() => {
			values.email && setSent(values.email);
		})
		.catch((error) => {
			if (!error.response) {
				notify('eiss.no_response', {type: 'warning'});
			}
		})
		.finally(() => {
			setLoading(false);
		});
	};

	return (
		<Form onSubmit={onSubmit} noValidate>
			<Box sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'flex-start'
				}}
			>
				<Card raised sx={{
						minWidth: 470,
						marginTop: '10em',
						backgroundColor: lightBlue[50]
					}}
				>
					<Logo />

				{(sent === "") ?
					<>
						<Box sx={{ padding: '0 1em 1em 1em' }}>
							<TextInput
								autoFocus
								source="email"
								label="Enter your account email address"
								disabled={loading}
								validate={required()}
								fullWidth
							/>
						</Box>
						<CardActions sx={{ padding: '0 7em 1em 7em' }}>
							<Button
								label='Send recovery message'
								variant='contained'
								type='submit'
								disabled={loading}
								fullWidth
							>
								{loading ? <CircularProgress size={25} thickness={2}/> : <></>}
							</Button>
						</CardActions>
					</>
				:
					<Box sx={{ padding: '0 1em 1em 1em' }}>
						<Typography align='center'>
							An email has been sent to the following address:
						</Typography>
						<Typography align='center' variant='h6'
							sx={{ padding: '1em 0 1em 0', color: red[500] }}>
							{sent}
						</Typography>
						<Typography align='center'>
							Please follow the instructions in email message<br/>
							to complete your password recovery.
						</Typography>
					</Box>
				}
				</Card>

				<Button sx={{ marginTop: '2em' }} label='back to login' onClick={() => {
					form.reset();
					redirect('/login');
				}} />

				<Notification />
			</Box>
		</Form>
	);
};

export default ForgotPassword;
