import { useState, useEffect } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { Title } from 'react-admin';
import { cardsUrl } from '../global';
import { blue, lightBlue } from '@mui/material/colors';

interface CardValues {
	text: string;
	icon: string;
	link: string;
	version: string;
}

const Dashboard = () => {
	const [cards, setCards] = useState<CardValues[]>([]);

	useEffect(() => {
		fetch(cardsUrl, {
			method: 'get',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(response => response.json())
		.then(data => Array.isArray(data) && setCards(data));
	}, []);

	return (
		<>
		<Title title='EISS™ Home' />
		<Grid container
			spacing='2em'
			justifyContent='center'
			sx={{ marginTop: '2em' }}
		>
			{cards.map((card, i) => {
				/* @ts-ignore */
				let Icon = Icons[card.icon];
				return (
					<Grid item key={i}>
						<a style={{textDecoration: 'none'}} href={card.link}>
							<Card raised sx={{
								height: 200,
								width: 220,
								textAlign: 'center',
								backgroundColor: theme => theme.palette.secondary.main
							}}>
								<CardContent>
									<Typography variant='body2' sx={{
										textAlign: 'right',
										color: blue[800]
									}}>
										{card.version}
									</Typography>
									<Icon sx={{
										fontSize: 48,
										color: lightBlue[100]
									}} />
									<Typography variant='h5' sx={{
										color: theme => theme.palette.common.white
									}}>
										{card.text}
									</Typography>
								</CardContent>
							</Card>
						</a>
					</Grid>
				);
			})}
		</Grid>
		</>
	);
};

export default Dashboard;
