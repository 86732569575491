import LoginService from './LoginService';
import TokenManager from './TokenManager';
import { authUrl } from '../global';

const tokenManager = new TokenManager({
	tokenUrl: `${authUrl}/token`,
	refreshUrl: `${authUrl}/refresh`,
	tokenStorageKey: "sessionjwt"
});

export const authProvider = LoginService(tokenManager);
export { i18nProvider } from "./i18nProvider";
