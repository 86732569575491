import { Layout } from "react-admin";
import EissAppBar from "./EissAppBar";

const EissLayout = (props: any) => (
	<Layout
		{...props}
		appBar={EissAppBar}
	/>
);

export default EissLayout;
