import {
	Box,
	Card,
	Typography
} from '@mui/material';
import {
	Button,
	useRedirect,
 } from 'react-admin';
import { lightBlue, red } from '@mui/material/colors';
import Logo from './Logo';

const Locked = () =>  {
	const redirect = useRedirect();

	return (
		<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh',
				alignItems: 'center',
				justifyContent: 'flex-start'
			}}
		>
			<Card raised sx={{
					minWidth: 470,
					marginTop: '10em',
					backgroundColor: lightBlue[50]
				}}
			>

				<Logo />

				<Box sx={{ padding: '0 1em 1em 1em' }}>
					<Typography variant='h6' align='center'
						sx={{ padding: '1em 0 1em 0', color: red[500] }}>
						Your account is locked!
					</Typography>
					<Typography align='center'>
						Please contact an administrator to unlock your account.
					</Typography>
				</Box>
			</Card>

			<Button sx={{ marginTop: '2em' }} label='back to login' onClick={() => {
				redirect('/login');
			}} />

		</Box>
	);
}

export default Locked;
