import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from "react-router-dom";
import {
	Box,
	Card,
	CardActions,
	CircularProgress,
	Typography
} from '@mui/material';
import {
	Form,
	Button,
	PasswordInput,
	useTranslate,
	useNotify,
	useRedirect,
	required
} from 'react-admin';
import { lightBlue, red } from '@mui/material/colors';
import Logo from './Logo';
import { resetService } from './resetService';
import processError from '../providers/processError';

const ResetPassword = () =>  {
	const [reason, setReason] = useState('');
	const [reset, setReset] = useState(false);
	const notify = useNotify();
	const params = useParams();
	const location = useLocation();

	useEffect(() => {
		params &&
		params.id &&
		resetService.validateCode(params.id)
		.catch(error => {
			if (!error.response) {
				notify('eiss.no_response', {type: 'warning'});
				return;
			}

			if (error.response.data === 'EXPIRED_CODE') {
				setReason('expired');
			} else if (error.response.data === 'INVALID_CODE') {
				setReason('invalid');
			}
		});
	}, [params, notify]);

	if (reason !== '') {
		return <InvalidCode reason={reason} />
	} else {
		if (reset) {
			return <ResetSuccess />
		} else {
			const pathArray = location.pathname.split("/");
			return <ResetForm setReset={setReset} type={pathArray[1]}/>
		}
	}
}

interface FormValues {
	password?: string;
}

const ResetForm = ({setReset, type}: {setReset: Dispatch<SetStateAction<boolean>>, type: string}) => {
	const [loading, setLoading] = useState(false);
	const notify = useNotify();
	const translate = useTranslate();
	const form = useForm();
	const redirect = useRedirect();

	const onSubmit = (values: FormValues) => {
		setLoading(true);

		return resetService.resetPassword(values.password)
		.then(() => {
			setLoading(false);
			setReset(true);
		})
		.catch((error) => {
			setLoading(false);
			processError(error, 'eiss.reset').catch(err => {
				notify(err.message, {type: 'warning'});
			});
		})
	};

	const isOldPasswords = async (values: FormValues) => {
		return resetService.validatePassword(values.password)
		.then(() => {
			return {};
		})
		.catch(() => {
			return { password: 'Old password already used' }
		})
	}

	const validatePassword = (values: FormValues) => {
		const errors: FormValues = {};

		if (!values.password) {
			errors.password = translate('ra.validation.required');
		} else {
			const password = values.password;
			const min = 8;
			const max = 40;
			const digit = "(?=.*\\d)";
			const lower = "(?=.*[a-z])";
			const upper = "(?=.*[A-Z])";
			const special = "(?=.*[@#$%!])";

			const match = new RegExp(`(${digit}${upper}${lower}${special}.{${min},${max}})`);

			if (!match.test(password)) {
				if (password.length < 8) {
					errors.password = 'Must be more than 8 characters long';
				}
				else if (password.length >= 40) {
					errors.password = 'Must be less than 40 characters long';
				}
				else if (!(new RegExp(digit)).test(password)){
					errors.password = 'Must contain at least one digit';
				}
				else if (!(new RegExp(upper)).test(password)) {
					errors.password = 'Must contain at least one upper case character';
				}
				else if (!(new RegExp(lower)).test(password)) {
					errors.password = 'Must contain at least one lower case character';
				}
				else if (!(new RegExp(special)).test(password)) {
					errors.password = 'Must contain at least one special character from [ @ # $ % ! . ]';
				}
			}
		}

		return Object.keys(errors).length ? errors : isOldPasswords(values);
	};

	return (
		<Form onSubmit={onSubmit} validate={validatePassword}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'flex-start'
				}}
			>
				<Card raised sx={{
					minWidth: 470,
					marginTop: '10em',
					backgroundColor: lightBlue[50]
				}}>

					<Logo />

					<Box sx={{ padding: '0 1em 1em 1em' }}>
						<Typography align='center' variant='h6'
							sx={{ padding: '1em 0 1em 0', color: red[500] }}>
							{(type === 'new') &&
								'Change the password for your new account'
							}
							{(type === 'reset') &&
								'Reset the password for your account'
							}
							{(type === 'expire') &&
								'Your current password has expired'
							}
						</Typography>

						<PasswordInput
							autoFocus
							source="password"
							label="Enter your new password"
							disabled={loading}
							validate={required()}
							fullWidth
						/>

						<CardActions sx={{ padding: '0 7em 1em 7em' }}>
							<Button
								label='Set password'
								variant='contained'
								type='submit'
								disabled={loading}
								fullWidth
							>
								{loading ? <CircularProgress size={25} thickness={2}/> : <></>}
							</Button>
						</CardActions>
					</Box>
				</Card>

				<Button sx={{ margin: '2em' }} label='back to login' onClick={() => {
					form.reset();
					redirect('/login');
				}} />
			</Box>
		</Form>
	);
}

const ResetSuccess = () => {
	const redirect = useRedirect();

	return (
		<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'flex-start'
			}}
		>
			<Card raised sx={{
				minWidth: 470,
				marginTop: '10em',
				backgroundColor: lightBlue[50]
			}}>

				<Logo />

				<Box sx={{ padding: '0 1em 1em 1em' }}>
					<Typography align='center'
						sx={{ padding: '1em 0 1em 0' }}
					>
						Password has successfully been reset
					</Typography>
				</Box>
			</Card>

			<Button sx={{ margin: '2em' }} label='back to login' onClick={() => {
				redirect('/login');
			}} />
		</Box>
	);
};

const InvalidCode = ({reason}: {reason: string}) => {
	const redirect = useRedirect();

	return (
		<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'flex-start'
			}}
		>
			<Card raised sx={{
				minWidth: 470,
				marginTop: '10em',
				backgroundColor: lightBlue[50]
			}}>

				<Logo />

				<Box sx={{ padding: '0 1em 1em 1em' }}>
					{reason === 'expired' &&
						<>
						<Typography align='center' variant='h6'
							sx={{ padding: '1em 0 1em 0', color: red[500] }}>
							This link has expired!
						</Typography>
						<Typography align='center'>
							Please request a new recovery message.
						</Typography>
						</>
					}
					{reason === 'invalid' &&
						<>
						<Typography align='center' variant='h6'
							sx={{ padding: '1em 0 1em 0', color: red[500] }}>
							This is not a valid link!
						</Typography>
						<Typography align='center'>
							Please check your email message.
						</Typography>
						</>
					}
				</Box>
			</Card>

			<Button sx={{ margin: '2em' }} label='back to login' onClick={() => {
				redirect('/login');
			}} />

		</Box>
	);
};

export default ResetPassword;
