import { development, authUrl } from '../global';

const contentType = {'Content-Type': 'application/json'}

class PasswordResetService {
	code: string = '';

	sendPasswordResetEmail = (email?: string) => {
		const url = window.location.protocol + '//' + window.location.host +
			(development ? "/#/reset/": "/ui/home/#/reset/");

		return fetch(`${authUrl}/reset/request`, {
			method: 'post',
			headers: contentType,
			body: JSON.stringify({ email, url })
		});
	}

	resetPassword = async (password?: string) => {
		return fetch(`${authUrl}/reset/password`, {
			method: 'post',
			headers: contentType,
			body: JSON.stringify({ code: this.code, password })
		})
		.then(() => this.code = '');
	}

	validateCode = async (_code: string) => {
		this.code = _code;
		return fetch(`${authUrl}/reset/validate/code`, {
			method: 'post',
			headers: contentType,
			body: JSON.stringify({ code: this.code })
		});
	}

	validatePassword = async (password?: string) => {
		return fetch(`${authUrl}/reset/validate/password`, {
			method: 'post',
			headers: contentType,
			body: JSON.stringify({ code: this.code, password })
		});
	}
}

export const resetService = new PasswordResetService();
