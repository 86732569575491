import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	Box,
	Card,
	CardActions,
	CircularProgress
} from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import {
	Form,
	Button,
	Notification,
	TextInput,
	useTranslate,
	useNotify,
	useRedirect,
	required
} from 'react-admin';
import Logo from './Logo';
import { authProvider } from '../providers';

interface FormValues {
	token?: string;
}

const MFA = () => {
	const [loading, setLoading] = useState(false);
	const translate = useTranslate();
	const notify = useNotify();
	const redirect = useRedirect();
	const form = useForm();

	const onSubmit = (values: FormValues) => {
		setLoading(true);

		return authProvider.checkMfa(values.token)
		.then(() => redirect('/dashboard'))
		.catch((error: Error) => {
			if (!error) {
				return notify('eiss.no_response', {type: 'warning'});
			} else {
				return notify('ra.auth.sign_in_error', {type: 'warning'});
			}
		})
		.finally(() => setLoading(false));
	};

	const validateToken = (values: FormValues) => {
		const errors: FormValues = {};
		if (!values.token) {
			errors.token = translate('ra.validation.required');
		} else {
			const token = values.token;
			if (!(/^\d+$/.test(token)) || token.length < 6 || token.length > 6) {
				errors.token = 'Must be 6 digits';
			}
		}
		return errors;
	};

	return (
		<Form onSubmit={onSubmit} validate={validateToken}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'flex-start'
				}}
			>
				<Card raised sx={{
					minWidth: 470,
					marginTop: '10em',
					backgroundColor: lightBlue[50]
				}}>

					<Logo />

					<Box sx={{ padding: '0 1em 1em 1em' }}>
						<TextInput
							autoFocus
							source="token"
							label="Type a verification code from the Authenticator"
							disabled={loading}
							validate={required()}
							fullWidth
						/>
					</Box>

					<CardActions sx={{ padding: '0 7em 1em 7em' }}>
						<Button
							label='Authenticate'
							variant='contained'
							type='submit'
							disabled={loading}
							fullWidth
						>
							{loading ? <CircularProgress size={25} thickness={2}/> : <></>}
						</Button>
					</CardActions>
				</Card>

				<Button sx={{ margin: '2em' }} label='back to login' onClick={() => {
					form.reset();
					redirect('/login');
				}} />

				<Notification />

			</Box>
		</Form>
	);
}

export default MFA;
