import polyglotI18nProvider from 'ra-i18n-polyglot';
import { TranslationMessages } from 'react-admin';
import englishMessages from "ra-language-english";

const customEnglishMessages: TranslationMessages = {
	...englishMessages,
	eiss: {
		auth: {
			expired: "Your session is expired",
			invalid: "Invalid credentials",
			failed: "Login failed",
			unable: "Unable to login at this time",
			process: "Error processing token",
			locked: "Account is locked"
		},
		mfa: {
			deactivated: "Deactivated MFA",
			fail: {
				deactivated: "Could no deactivate MFA",
				wrong: "Failed to activate MFA. Wrong Authentication codes.",
				server: '"MFA auth server error'
			}
		},
		rules: {
			update: "Updated Rules"
		},
		server_error: "Server error",
		no_response: "No response from server"
	}
};

export const i18nProvider = polyglotI18nProvider(_locale => customEnglishMessages, 'en');
